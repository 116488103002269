<template>
  <ODNField :error="error" :warning="warning">
    <ODNLabel
      :label="
        surveyField.name || $t(`surveyFields.${surveyField.field.name}.label`)
      "
    />
    <ion-button color="dark" expand="block" @click="onModalQrcode">
      <ion-icon slot="start" :icon="icons.qrCodeOutline"></ion-icon>
      {{ $t('buttons.scan') }}
    </ion-button>
    <ion-item lines="none">
      <ion-label position="floating">
        {{ $t('fields.uuid.label') }}
      </ion-label>
      <ion-input @ionChange="onChange" v-model="values.uuid"></ion-input>
    </ion-item>
    <ion-item lines="none">
      <ion-label position="floating">
        {{ $t('fields.imei.label') }}
      </ion-label>
      <ion-input @ionChange="onChange" v-model="values.imei"></ion-input>
    </ion-item>
    <ion-item lines="none">
      <ion-label position="floating">
        {{ $t('fields.serialNumber.label') }}
      </ion-label>
      <ion-input
        @ionChange="onChange"
        v-model="values.serialNumber"
      ></ion-input>
    </ion-item>
    <ion-item lines="none">
      <ion-label position="floating">
        {{ $t('fields.model.label') }}
      </ion-label>
      <ion-input @ionChange="onChange" v-model="values.model"></ion-input>
    </ion-item>
  </ODNField>
</template>

<script>
import {
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonIcon,
  modalController,
  toastController,
} from '@ionic/vue';
import { qrCodeOutline } from 'ionicons/icons';

import ODNField from '@c/fields/odn-field.vue';
import ODNLabel from '@c/odn-label.vue';

import ODNModalQrcode from '@m/odn-modal-qrcode.vue';

export default {
  components: {
    IonItem,
    IonLabel,
    IonInput,
    IonButton,
    IonIcon,
    ODNField,
    ODNLabel,
  },
  props: {
    modelValue: String,
    surveyField: Object,
    submitted: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    error() {
      return this.submitted &&
        this.surveyField.isRequired &&
        !this.surveyField.response
        ? this.$t('errors.required')
        : null;
    },
    warning() {
      return this.surveyField.warning ? this.$t('warnings.productUUID') : null;
    },
  },
  data() {
    return {
      values: {
        uuid: null,
        imei: null,
        serialNumber: null,
        model: null,
      },
      icons: {
        qrCodeOutline,
      },
    };
  },
  watch: {
    modelValue: {
      immediate: true,
      handler() {
        this.parseValues();
      },
    },
  },
  emits: ['update:modelValue'],
  methods: {
    async parseValues() {
      if (this.modelValue) {
        try {
          this.values = JSON.parse(this.modelValue);
        } catch (error) {
          const toast = await toastController.create({
            message: this.$t('messages.scan.parse.error'),
            color: 'danger',
            duration: 2000,
          });
          toast.present();
        }
      }
    },
    async parseQrCode(str) {
      try {
        const json = JSON.parse(str);
        this.values = {
          uuid: json.UUID || null,
          imei: json.IMEI || null,
          serialNumber: json.SN || null,
          model: json.Model || null,
        };
      } catch (error) {
        const toast = await toastController.create({
          message: this.$t('messages.scan.parse.error'),
          color: 'danger',
          duration: 2000,
        });
        toast.present();
      }
      return {};
    },
    async onModalQrcode() {
      const modal = await modalController.create({
        component: ODNModalQrcode,
      });
      await modal.present();

      // Get result from scan
      const result = (await modal.onDidDismiss()).data;

      if (result) {
        // Parse json string and assign values
        await this.parseQrCode(result);

        // Return values and stringified data
        this.$emit('update:modelValue', JSON.stringify(this.values));
      }
    },
    onChange() {
      this.$emit('update:modelValue', JSON.stringify(this.values));
    },
  },
};
</script>
