<template>
  <div
    class="odn-field"
    :class="{ 'odn-field-error': error, 'odn-field-warning': warning }"
  >
    <slot />
    <div v-if="error" class="odn-field-error-message">
      <ion-icon :icon="icons.warning"></ion-icon>
      {{ error }}
    </div>
    <div v-if="warning" class="odn-field-warning-message">
      <ion-icon :icon="icons.warning"></ion-icon>
      {{ warning }}
    </div>
  </div>
</template>

<script>
import { IonIcon } from '@ionic/vue';
import { warning } from 'ionicons/icons';

export default {
  components: {
    IonIcon,
  },
  props: {
    error: {
      type: String,
      default: null,
    },
    warning: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      icons: { warning },
    };
  },
};
</script>

<style lang="scss">
.odn-field {
  padding: 8px;
  background-color: rgba(var(--ion-color-light-contrast-rgb), 0.05);
  border-radius: 8px;

  &-error {
    background-color: rgba(var(--ion-color-danger-rgb), 0.2);
  }

  &-warning {
    background-color: rgba(var(--ion-color-warning-rgb), 0.2);
  }

  &-error-message {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px 16px 16px;
    font-size: 0.8em;
    color: var(--ion-color-danger);

    > ion-icon {
      margin-right: 0.5rem;
    }
  }

  > .ion-focused {
    border-radius: 6px;
  }

  > ion-item {
    --background: transparent;
  }

  img,
  video {
    display: block;
    width: calc(100% - 6px);
    margin: 0 auto 8px auto;
    border-radius: 4px;
  }

  ion-button {
    margin-top: 8px;
  }
}
</style>
