<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="end">
          <ion-button @click="dismiss">
            <ion-icon slot="icon-only" :icon="icons.close"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title>
          {{ $t('modals.qrcode') }}
        </ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <div class="ion-padding">
        <ODNVideoScanner>
          <video
            ref="preview"
            width="400"
            height="300"
            class="video-preview"
          ></video>
        </ODNVideoScanner>
        <div class="odn-mat-16">
          <ODNFormField :label="$t('fields.camera.label')">
            <ion-select
              :value="selectedDeviceId"
              :placeholder="$t('fields.camera.placeholder')"
              :cancelText="$t('buttons.cancel')"
              :okText="$t('buttons.validate')"
              interface="action-sheet"
              @ion-change="switchDevice"
            >
              <ion-select-option
                v-for="videoInputDevice in videoInputDevices"
                :key="videoInputDevice.deviceId"
                :value="videoInputDevice.deid"
              >
                {{ videoInputDevice.label }}
              </ion-select-option>
            </ion-select>
          </ODNFormField>
        </div>
        <div class="odn-mat-16">
          <ion-button color="dark" expand="block" @click="dismiss">
            {{ $t('buttons.cancel') }}
            <ion-icon slot="end" :icon="icons.close"></ion-icon>
          </ion-button>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { BrowserCodeReader, BrowserQRCodeReader } from '@zxing/browser';

import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonButtons,
  IonIcon,
  IonSelect,
  IonSelectOption,
  modalController,
  loadingController,
} from '@ionic/vue';
import { close } from 'ionicons/icons';

import ODNVideoScanner from '@c/odn-video-scanner.vue';
import ODNFormField from '@c/odn-form-field.vue';

export default {
  name: 'ModalQrcode',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButton,
    IonButtons,
    IonIcon,
    IonSelect,
    IonSelectOption,
    ODNVideoScanner,
    ODNFormField,
  },
  data() {
    return {
      barcode: null,
      controls: null,
      videoInputDevices: null,
      selectedDeviceId: null,
      icons: {
        close,
      },
    };
  },
  mounted() {
    this.startScan();
  },
  unmounted() {
    if (this.controls) {
      this.controls.stop();
    }
  },
  methods: {
    async startScan() {
      const codeReader = new BrowserQRCodeReader();

      if (!this.selectedDeviceId) {
        this.videoInputDevices = await BrowserCodeReader.listVideoInputDevices();
        this.selectedDeviceId = this.videoInputDevices[
          this.videoInputDevices.length - 1
        ].deviceId;
      }

      if (this.controls) {
        this.controls.stop();
      }

      this.controls = await codeReader.decodeFromVideoDevice(
        this.selectedDeviceId,
        this.$refs.preview,
        (result, error, controls) => {
          if (result) {
            controls.stop();
            modalController.dismiss(result.text);
          }
        }
      );
    },
    async switchDevice() {
      const loadingHandler = await loadingController.create({
        message: this.$t('messages.loading'),
      });
      loadingHandler.present();

      try {
        this.controls.stop();
        setTimeout(() => {
          this.startScan();
          loadingHandler.dismiss();
        }, 3000);
      } catch (e) {
        loadingHandler.dismiss();
      }
    },
    dismiss() {
      modalController.dismiss();
    },
  },
};
</script>
