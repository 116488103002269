<template>
  <label class="odn-label">
    {{ label }}
  </label>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss">
.odn-label {
  display: block;
  margin: 11px 0 10px;
  padding: 0 16px;
}
</style>
