<template>
  <div class="odn-video-scanner">
    <div class="odn-video-scanner-target"></div>
    <slot />
  </div>
</template>

<style lang="scss">
.odn-video-scanner {
  position: relative;
  width: 100%;
  height: 400px;
  padding: 8px;
  background-color: rgba(var(--ion-color-light-contrast-rgb), 0.05);
  border-radius: 8px;

  &-target {
    position: absolute;
    width: 70%;
    height: 80%;
    top: 50%;
    left: 50%;
    border: 1px dashed var(--ion-color-danger);
    transform: translate(-50%, -50%);
    border-radius: 8px;
  }

  video {
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }
}
</style>
